import _ from 'lodash'
import { getContractRecord } from '../../../apollo/callRecordQueries'
import { useQuery } from '@apollo/client'

export const useContractRecord = (getContractRecordId) => {
  const { data, loading, refetch } = useQuery(getContractRecord, {
    variables: {
      getContractRecordId
    },
    fetchPolicy: 'network-only'
  })
  const gqlData = _.cloneDeep(_.get(data, ['GetContractRecord']))
  if (_.has(gqlData, 'paymentRecords')) {
    _.forEach(gqlData.paymentRecords, (record) => {
      if (_.has(record, 'dueAmount')) {
        record.dueAmount = parseInt(record.dueAmount, 10)
      }
      if (_.has(record, 'deposits')) {
        _.forEach(record.deposits, (deposit) => {
          if (_.has(deposit, 'receivedAmount')) {
            deposit.receivedAmount = parseInt(deposit.receivedAmount, 10)
          }
        })
      }
    })
  }

  return {
    loading,
    contractRecordData: gqlData,
    totalCount: _.get(data, ['GetContractRecordList', 'totalCount'], 0),
    refetch
  }
}

import React, { useState } from 'react'
import UserFilter from '../../../../timeCharge/components/filters/UserFilter'
import { useUserFilter } from '../../../../common/hooks/useUserFilter'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import _ from 'lodash'

export const FindUser = ({ pageType, currentUser, form, index, initialValue, dataType }) => {
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const {
    loading,
    meta,
    filters,
    onChangeMeta,
    onResetFilterAndGroup,
    selectedItems
  } = useUserFilter(
    form,
    index,
    initialValue,
    dataType,
    pageType
  )

  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)

  return (
    <UserFilter
      loading={loading}
      meta={meta}
      filters={filters}
      departments={departments}
      onChangeMeta={onChangeMeta}
      onResetFilterAndGroup={onResetFilterAndGroup}
      selectedOptions={selectedItems}
      selectedItems={selectedItems}
      currentUser={currentUser}
      initialValue={initialValue}
    />
  )
}

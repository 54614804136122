import _ from 'lodash'
import { timesheetTitles } from './common/config'
import { uuidv4 } from './common/util'
// timelist query
export const getDD = (v) => {
  return v >= 10 ? v : `0${v}`
}

export const generateTimeListData = (caseId, caseNumber, userId, userName) => {
  return (
    _.range(1, 10).map(v => {
      return {
        userId,
        key: v,
        title: timesheetTitles[Math.round(Math.random() * 3)],
        caseNumber,
        caseId,
        creator: userName,
        created: `2024-01-${getDD(v)} 12:12:00`,
        updated: `2024-01-${getDD(v)} 23:12:00`,
        minutes: Math.round(Math.random() * 1000)
      }
    })
  )
}

export const caseListData = _.range(1, 11).flatMap(v => {
  const nUsers = Math.ceil(Math.random() * 10)
  let totalMinutes = 0
  let totalMinutesAfterEdit = 0

  return [
    {
      key: `caseId_${2 * v - 1}`,
      starred: true,
      caseNumber: `2021가합${6 * v - 1} 손해배상`,
      updated: `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes + Math.round(Math.random() * 50) - Math.round(Math.random() * 50)
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          key: `userId_${2 * v - 1}_${i}`,
          updated: '2024-01-24 23:12:00',
          // name: `사용자_${2 * v - 1}_${i}`,
          name: '사용자',
          minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v - 1}`, `사건번호 ${2 * v - 1}`, `userId_${2 * v - 1}_${i}`, `사용자_${2 * v - 1}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit
    },
    {
      key: `caseId_${2 * v}`,
      starred: false,
      caseNumber: `사건번호 ${2 * v}`,
      updated: `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          key: `userId_${2 * v}_${i}`,
          updated: '2024-01-24 23:12:00',
          name: `사용자_${2 * v}_${i}`,
          minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v}`, `사건번호 ${2 * v}`, `userId_${2 * v}_${i}`, `사용자_${2 * v}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit
    }
  ]
})

export const caseNumbers = caseListData.map(({ key, caseNumber }) => ({ key, value: caseNumber }))
export const users = _.flatMap(caseListData, 'users').map(({ key, name }) => {
  return {
    key,
    value: name
  }
})
export const colors = [
  'purple',
  'skyBlue',
  'green',
  'lightGreen'
]
export const timeListData = _.range(1, 30).map(v => {
  const rand = Math.round(Math.random() * 3)
  return {
    key: `timesheet_1_${v}`,
    color: colors[rand],
    title: timesheetTitles[Math.round(Math.random() * 3)],
    caseNumber: caseNumbers[rand].value,
    caseId: caseNumbers[rand].key,
    creator: '김길동',
    created: `2024-01-${getDD(v)} 12:12:00`,
    updated: `2024-01-${getDD(v)} 23:12:00`,
    minutes: Math.round(Math.random() * 1000)
  }
}).concat(
  _.range(1, 30).map(v => {
    const rand = Math.round(Math.random() * 3)

    return {
      key: `timesheet_2_${v}`,
      color: colors[rand],
      title: timesheetTitles[Math.round(Math.random() * 3)],
      caseNumber: caseNumbers[rand].value,
      caseId: caseNumbers[rand].key,
      creator: '김길동',
      created: `2024-01-${getDD(v)} 12:12:00`,
      updated: `2024-01-${getDD(v)} 23:12:00`,
      minutes: Math.round(Math.random() * 1000)
    }
  })
)

// 통계
export const createGraphData = (timeGroup) => {
  return _.range(timeGroup === 'weekly' ? 4 : 10).flatMap(v => {
    return _.range(1, 10).map(i => {
      return {
        nTimesheets: Math.round(Math.random() * 10),
        date: timeGroup === 'weekly' ? [`2024-01-${(v * 7) + 1}`, `2024-01-${(v * 7) + 8}`] : [`2024-01-${v + 1}`],
        creator: `사용자 ${i}`,
        caseNumber: `사건번호 ${i}`
      }
    })
  })
}

export const pushNotifications = _.range(10).map(v => {
  const date = new Date()
  date.setDate(date.getDate() - v)
  return (
    {
      text: `사건 ${v}에 추가되었습니다.`,
      date: date,
      reviewed: v > 3,
      type: ''
    }
  )
})

export const contractDuplication = [
  {
    key: '0',
    ID: 123123,
    SN: 'YK0120240101',
    category: '형사',
    subcategory: '형사'
  }
]
// 대분류, 중분류, 소분류
export const projectTypes = [
  {
    key: '1',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '강간등(유사강간, 의제강간 등 포함)'
  },
  {
    key: '2',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '강제추행등(준강제추행 등 포함)'
  },
  {
    key: '3',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '카메라등이용촬영등(촬영물이용협박, 유포 등 포함)'
  },
  {
    key: '4',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '아청법위반등(아청강간, 아청강제추행 등 포함)'
  },
  {
    key: '5',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '아청성착취물등(음란물 등 포함)'
  },
  {
    key: '6',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '성매매등(성매매알선등 포함)'
  },
  {
    key: '7',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '공연음란등'
  },
  {
    key: '8',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '통신매체이용음란'
  },
  {
    key: '9',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '디지털성범죄등(딥페이크 범죄 등)'
  },
  {
    key: '10',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '군성범죄'
  },
  {
    key: '11',
    firstCategory: '형사',
    secondCategory: '성범죄',
    thirdCategroy: '기타'
  },
  {
    key: '12',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '일반사기등(사기, 보이스피싱등)'
  },
  {
    key: '13',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '횡령등(횡령, 배임, 업무상 횡령 등)'
  },
  {
    key: '14',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '특경법'
  },
  {
    key: '15',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '유사수신등(전자금융거래법위반 등 포함)'
  },
  {
    key: '16',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '코인관련범죄'
  },
  {
    key: '17',
    firstCategory: '형사',
    secondCategory: '경제범죄',
    thirdCategroy: '기타'
  },
  {
    key: '18',
    firstCategory: '형사',
    secondCategory: '강력범죄',
    thirdCategroy: '살인등(살인, 미수, 치사 등)'
  },
  {
    key: '19',
    firstCategory: '형사',
    secondCategory: '강력범죄',
    thirdCategroy: '강도등'
  },
  {
    key: '20',
    firstCategory: '형사',
    secondCategory: '강력범죄',
    thirdCategroy: '방화등'
  },
  {
    key: '21',
    firstCategory: '형사',
    secondCategory: '강력범죄',
    thirdCategroy: '기타'
  },
  {
    key: '22',
    firstCategory: '형사',
    secondCategory: '교통범죄',
    thirdCategroy: '음주운전(음주운전, 음주측정거부등)'
  },
  {
    key: '23',
    firstCategory: '형사',
    secondCategory: '교통범죄',
    thirdCategroy: '특가법등(도주치사상, 위험운전치사상 등)'
  },
  {
    key: '24',
    firstCategory: '형사',
    secondCategory: '교통범죄',
    thirdCategroy: '무면허운전'
  },
  {
    key: '25',
    firstCategory: '형사',
    secondCategory: '교통범죄',
    thirdCategroy: '도로교통법위반(보복운전(특수협박), 손괴 등)'
  },
  {
    key: '26',
    firstCategory: '형사',
    secondCategory: '교통범죄',
    thirdCategroy: '기타'
  },
  {
    key: '27',
    firstCategory: '형사',
    secondCategory: '마약범죄',
    thirdCategroy: '제조유통등(제조, 유통, 밀반입 등)'
  },
  {
    key: '28',
    firstCategory: '형사',
    secondCategory: '마약범죄',
    thirdCategroy: '대마(소지, 매매, 투약, 흡입 등)'
  },
  {
    key: '29',
    firstCategory: '형사',
    secondCategory: '마약범죄',
    thirdCategroy: '항정(소지, 매매, 투약, 흡입 등)'
  },
  {
    key: '30',
    firstCategory: '형사',
    secondCategory: '도박',
    thirdCategroy: '온라인도박'
  },
  {
    key: '31',
    firstCategory: '형사',
    secondCategory: '도박',
    thirdCategroy: '오프라인도박'
  },
  {
    key: '32',
    firstCategory: '형사',
    secondCategory: '도박',
    thirdCategroy: '기타'
  },
  {
    key: '33',
    firstCategory: '형사',
    secondCategory: '학교폭력',
    thirdCategroy: '학폭위'
  },
  {
    key: '34',
    firstCategory: '형사',
    secondCategory: '학교폭력',
    thirdCategroy: '학폭형사'
  },
  {
    key: '35',
    firstCategory: '형사',
    secondCategory: '학교폭력',
    thirdCategroy: '기타(불복절차 및 행정 등)'
  },
  {
    key: '36',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '폭행등'
  },
  {
    key: '37',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '절도등'
  },
  {
    key: '38',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '명예훼손등(야간주거침입절도 등 포함)'
  },
  {
    key: '39',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '주거침입등(야간주거침입절도 등 포함'
  },
  {
    key: '40',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '금융범죄등(조세, 공정거래 범죄 등 포함)'
  },
  {
    key: '41',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '부패범죄등(공공범죄, 선거범죄, 뇌물수수 등 포함)'
  },
  {
    key: '42',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '스토킹등'
  },
  {
    key: '43',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '아동복지법위반등(아동학대, 아동복지법위반등)'
  },
  {
    key: '44',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '위조등(공문서위조, 사문서위조,공정종서불실기재, 위작변작)'
  },
  {
    key: '45',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '공무집행방해등'
  },
  {
    key: '46',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '정통망법위반'
  },
  {
    key: '47',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '개인정보보호법위반'
  },
  {
    key: '48',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '해양'
  },
  {
    key: '49',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '영장대응'
  },
  {
    key: '50',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '합의대행'
  },
  {
    key: '51',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '직장내괴롭힘'
  },
  {
    key: '52',
    firstCategory: '형사',
    secondCategory: '일반형사',
    thirdCategroy: '기타'
  },
  {
    key: '53',
    firstCategory: '형사',
    secondCategory: '군형사',
    thirdCategroy: '군폭행등(근무지이탈, 초병폭행등 포함)'
  },
  {
    key: '54',
    firstCategory: '형사',
    secondCategory: '군형사',
    thirdCategroy: '상관모욕등'
  },
  {
    key: '55',
    firstCategory: '형사',
    secondCategory: '군형사',
    thirdCategroy: '군징계등'
  },
  {
    key: '56',
    firstCategory: '형사',
    secondCategory: '군형사',
    thirdCategroy: '기타(군용물절도등)'
  },
  {
    key: '57',
    firstCategory: '형사',
    secondCategory: '소년사건',
    thirdCategroy: '소년사건일체'
  },
  {
    key: '58',
    firstCategory: '형사',
    secondCategory: '고소,고발',
    thirdCategroy: '성범죄고소'
  },
  {
    key: '59',
    firstCategory: '형사',
    secondCategory: '고소,고발',
    thirdCategroy: '경제범죄고소'
  },
  {
    key: '60',
    firstCategory: '형사',
    secondCategory: '고소,고발',
    thirdCategroy: '기타'
  },
  {
    key: '61',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '손해배상'
  },
  {
    key: '62',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '대여금(대여금, 금전)'
  },
  {
    key: '63',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '부당이득금'
  },
  {
    key: '64',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '보증금'
  },
  {
    key: '65',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '명도소송'
  },
  {
    key: '66',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '상간소송'
  },
  {
    key: '67',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '투자금'
  },
  {
    key: '68',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '약정금'
  },
  {
    key: '69',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '정산금'
  },
  {
    key: '70',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '계약금'
  },
  {
    key: '71',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '양수금'
  },
  {
    key: '72',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '추심전부금(추심금, 전부금)'
  },
  {
    key: '73',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '용역대금'
  },
  {
    key: '74',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '매매대금(물품대금, 매매대금)'
  },
  {
    key: '75',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '배당이의청구이의(배당이의, 청구이의)'
  },
  {
    key: '76',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '사해행위'
  },
  {
    key: '77',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '채무확인(채무부존재)'
  },
  {
    key: '78',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '내용증명'
  },
  {
    key: '79',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '보전처분(가압류,가처분)'
  },
  {
    key: '80',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '계약해제(계약해지, 계약해제)'
  },
  {
    key: '81',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '소유권이전등기말소'
  },
  {
    key: '82',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '근저당말소'
  },
  {
    key: '83',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '강제집행(압류추심전부, 강제집행정지, 경매)'
  },
  {
    key: '84',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '가등기말소'
  },
  {
    key: '85',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '철거 등'
  },
  {
    key: '86',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '법률자문'
  },
  {
    key: '87',
    firstCategory: '민가사',
    secondCategory: '민사',
    thirdCategroy: '기타'
  },
  {
    key: '88',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '이혼'
  },
  {
    key: '89',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '사실혼관계해소'
  },
  {
    key: '90',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '혼인무효'
  },
  {
    key: '91',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '혼인취소'
  },
  {
    key: '92',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '재산분할청구'
  },
  {
    key: '93',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '위자료청구'
  },
  {
    key: '94',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '인지청구'
  },
  {
    key: '95',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '양육비청구'
  },
  {
    key: '96',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '친권(친권양육권변경, 친생자부존재)'
  },
  {
    key: '97',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '상속'
  },
  {
    key: '98',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '유류분'
  },
  {
    key: '99',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '후견(성년후견, 한정후견)'
  },
  {
    key: '100',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '상속포기한정승인(상속포기,한정승인)'
  },
  {
    key: '101',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '사전처분'
  },
  {
    key: '102',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '개명(개명, 성본변경)'
  },
  {
    key: '103',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '입양'
  },
  {
    key: '104',
    firstCategory: '민가사',
    secondCategory: '가사',
    thirdCategroy: '기타'
  },
  {
    key: '105',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '해고(부당해고, 해고무효)'
  },
  {
    key: '106',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '임금퇴직금(임금,퇴직금)'
  },
  {
    key: '107',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '근로기준법'
  },
  {
    key: '108',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '산재'
  },
  {
    key: '109',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '행정등(행정심판,행정소송,소청)'
  },
  {
    key: '110',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '직장내괴롭힘'
  },
  {
    key: '111',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '중대재해'
  },
  {
    key: '112',
    firstCategory: '기타',
    secondCategory: '노동,산재,행정',
    thirdCategroy: '기타'
  },
  {
    key: '113',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '공사대금'
  },
  {
    key: '114',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '재개발,재건축'
  },
  {
    key: '115',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '점유이전금지가처분'
  },
  {
    key: '116',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '건물인도'
  },
  {
    key: '117',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '분양대금'
  },
  {
    key: '118',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '하자담보'
  },
  {
    key: '119',
    firstCategory: '기타',
    secondCategory: '부동산,건설',
    thirdCategroy: '기타'
  },
  {
    key: '120',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '일반소송'
  },
  {
    key: '121',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '부정경쟁'
  },
  {
    key: '122',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '영업비밀'
  },
  {
    key: '123',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '특허'
  },
  {
    key: '124',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '지식재산권(저작권,지식재산권,상표권)'
  },
  {
    key: '125',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '기업자문'
  },
  {
    key: '126',
    firstCategory: '기타',
    secondCategory: '기업',
    thirdCategroy: '기타'
  },
  {
    key: '127',
    firstCategory: '기타',
    secondCategory: '의료',
    thirdCategroy: '손해배상(의료과실)'
  },
  {
    key: '127',
    firstCategory: '기타',
    secondCategory: '의료',
    thirdCategroy: '의료자문'
  },
  {
    key: '127',
    firstCategory: '기타',
    secondCategory: '의료',
    thirdCategroy: '기타'
  }
]
export const projectTypesData = [
  {
    index: 0,
    대: '형사',
    중: '성범죄',
    소: '강간등',
    note: '유사강간, 의제강간 등 포함'
  },
  {
    index: 1,
    대: '형사',
    중: '성범죄',
    소: '강제추행등',
    note: '준강제추행 등 포함'
  },
  {
    index: 2,
    대: '형사',
    중: '성범죄',
    소: '카메라등이용촬영등',
    note: '촬영물이용협박, 유포 등 포함'
  },
  {
    index: 3,
    대: '형사',
    중: '성범죄',
    소: '아청법위반등',
    note: '아청강간, 아청강제추행 등 포함'
  },
  {
    index: 4,
    대: '형사',
    중: '성범죄',
    소: '아청성착취물등',
    note: '음란물 등 포함'
  },
  {
    index: 5,
    대: '형사',
    중: '성범죄',
    소: '성매매등',
    note: '성매매알선등 포함'
  },
  { index: 6, 대: '형사', 중: '성범죄', 소: '공연음란등', note: '' },
  { index: 7, 대: '형사', 중: '성범죄', 소: '통신매체이용음란', note: '' },
  {
    index: 8,
    대: '형사',
    중: '성범죄',
    소: '디지털성범죄등',
    note: '딥페이크 범죄 등'
  },
  { index: 9, 대: '형사', 중: '성범죄', 소: '군성범죄', note: '' },
  { index: 10, 대: '형사', 중: '성범죄', 소: '기타', note: '' },
  {
    index: 11,
    대: '형사',
    중: '경제범죄',
    소: '일반사기등',
    note: '사기, 보이스피싱등'
  },
  {
    index: 12,
    대: '형사',
    중: '경제범죄',
    소: '횡령등',
    note: ' 횡령, 배임, 업무상 횡령 등'
  },
  { index: 13, 대: '형사', 중: '경제범죄', 소: '특경법', note: '' },
  {
    index: 14,
    대: '형사',
    중: '경제범죄',
    소: '유사수신등',
    note: ' 전자금융거래법위반 등 포함'
  },
  { index: 15, 대: '형사', 중: '경제범죄', 소: '코인관련범죄', note: '' },
  { index: 16, 대: '형사', 중: '경제범죄', 소: '기타', note: '' },
  {
    index: 17,
    대: '형사',
    중: '강력범죄',
    소: '살인등',
    note: ' 살인, 미수, 치사 등'
  },
  { index: 18, 대: '형사', 중: '강력범죄', 소: '강도등', note: '' },
  { index: 19, 대: '형사', 중: '강력범죄', 소: '방화등', note: '' },
  { index: 20, 대: '형사', 중: '강력범죄', 소: '기타', note: '' },
  {
    index: 21,
    대: '형사',
    중: '교통범죄',
    소: '음주운전',
    note: '음주운전, 음주측정거부등'
  },
  {
    index: 22,
    대: '형사',
    중: '교통범죄',
    소: '특가법등',
    note: '도주치사상, 위험운전치사상 등'
  },
  { index: 23, 대: '형사', 중: '교통범죄', 소: '무면허운전', note: '' },
  {
    index: 24,
    대: '형사',
    중: '교통범죄',
    소: '도로교통법위반',
    note: '보복운전(특수협박), 손괴 등'
  },
  { index: 25, 대: '형사', 중: '교통범죄', 소: '기타', note: '' },
  {
    index: 26,
    대: '형사',
    중: '마약범죄',
    소: '제조유통등',
    note: '제조, 유통, 밀반입 등'
  },
  {
    index: 27,
    대: '형사',
    중: '마약범죄',
    소: '대마',
    note: '소지, 매매, 투약, 흡입 등'
  },
  {
    index: 28,
    대: '형사',
    중: '마약범죄',
    소: '향정',
    note: '소지, 매매, 투약, 흡입 등'
  },
  { index: 29, 대: '형사', 중: '도박', 소: '온라인도박', note: '' },
  { index: 30, 대: '형사', 중: '도박', 소: '오프라인도박', note: '' },
  { index: 31, 대: '형사', 중: '도박', 소: '기타', note: '' },
  { index: 32, 대: '형사', 중: '학교폭력', 소: '학폭위', note: '' },
  { index: 33, 대: '형사', 중: '학교폭력', 소: '학폭형사', note: '' },
  {
    index: 34,
    대: '형사',
    중: '학교폭력',
    소: '기타',
    note: ' 불복절차 및 행정 등'
  },
  {
    index: 35,
    대: '형사',
    중: '일반형사',
    소: '폭행등',
    note: '폭행, 상해, 협박, 업무방해등'
  },
  { index: 36, 대: '형사', 중: '일반형사', 소: '절도등', note: '' },
  {
    index: 37,
    대: '형사',
    중: '일반형사',
    소: '명예훼손등',
    note: '야간주거침입절도 등 포함'
  },
  {
    index: 38,
    대: '형사',
    중: '일반형사',
    소: '주거침입등',
    note: '야간주거침입절도 등 포함'
  },
  {
    index: 39,
    대: '형사',
    중: '일반형사',
    소: '금융범죄등',
    note: '조세, 공정거래 범죄 등 포함'
  },
  {
    index: 40,
    대: '형사',
    중: '일반형사',
    소: '부패범죄등',
    note: '공공범죄, 선거범죄, 뇌물수수 등 포함'
  },
  { index: 41, 대: '형사', 중: '일반형사', 소: '스토킹등', note: '' },
  {
    index: 42,
    대: '형사',
    중: '일반형사',
    소: '아동복지법위반등',
    note: '아동학대, 아동복지법위반등'
  },
  {
    index: 43,
    대: '형사',
    중: '일반형사',
    소: '위조등',
    note: '공문서위조, 사문서위조,공정종서불실기재, 위작변작'
  },
  { index: 44, 대: '형사', 중: '일반형사', 소: '공무집행방해등', note: '' },
  { index: 45, 대: '형사', 중: '일반형사', 소: '정통망법위반', note: '' },
  {
    index: 46,
    대: '형사',
    중: '일반형사',
    소: '개인정보보호법위반',
    note: ''
  },
  { index: 47, 대: '형사', 중: '일반형사', 소: '해양', note: '' },
  { index: 48, 대: '형사', 중: '일반형사', 소: '영장대응', note: '' },
  { index: 49, 대: '형사', 중: '일반형사', 소: '합의대행', note: '' },
  { index: 50, 대: '형사', 중: '일반형사', 소: '직장내괴롭힘', note: '' },
  { index: 51, 대: '형사', 중: '일반형사', 소: '기타', note: '' },
  {
    index: 52,
    대: '형사',
    중: '군형사',
    소: '군폭행등',
    note: '근무지이탈, 초병폭행등 포함'
  },
  { index: 53, 대: '형사', 중: '군형사', 소: '상관모욕등', note: '' },
  { index: 54, 대: '형사', 중: '군형사', 소: '군징계등', note: '' },
  { index: 55, 대: '형사', 중: '군형사', 소: '기타', note: '군용물절도등' },
  { index: 56, 대: '형사', 중: '소년사건', 소: '소년사건일체', note: '' },
  { index: 57, 대: '형사', 중: '고소,고발', 소: '성범죄고소', note: '' },
  { index: 58, 대: '형사', 중: '고소,고발', 소: '경제범죄고소', note: '' },
  { index: 59, 대: '형사', 중: '고소,고발', 소: '기타', note: '' },
  { index: 60, 대: '민가사', 중: '민사', 소: '손해배상', note: '' },
  { index: 61, 대: '민가사', 중: '민사', 소: '대여금', note: '' },
  {
    index: 62,
    대: '민가사',
    중: '민사',
    소: '부당이득금',
    note: '대여금, 금전'
  },
  { index: 63, 대: '민가사', 중: '민사', 소: '보증금', note: '' },
  { index: 64, 대: '민가사', 중: '민사', 소: '명도소송', note: '' },
  { index: 65, 대: '민가사', 중: '민사', 소: '상간소송', note: '' },
  { index: 66, 대: '민가사', 중: '민사', 소: '투자금', note: '' },
  { index: 67, 대: '민가사', 중: '민사', 소: '약정금', note: '' },
  { index: 68, 대: '민가사', 중: '민사', 소: '정산금', note: '' },
  { index: 69, 대: '민가사', 중: '민사', 소: '계약금', note: '' },
  { index: 70, 대: '민가사', 중: '민사', 소: '양수금', note: '' },
  {
    index: 71,
    대: '민가사',
    중: '민사',
    소: '추심전부금',
    note: '추심금, 전부금'
  },
  { index: 72, 대: '민가사', 중: '민사', 소: '용역대금', note: '' },
  {
    index: 73,
    대: '민가사',
    중: '민사',
    소: '매매대금',
    note: '물품대금, 매매대금'
  },
  {
    index: 74,
    대: '민가사',
    중: '민사',
    소: '배당이의청구이의',
    note: '배당이의, 청구이의'
  },
  { index: 75, 대: '민가사', 중: '민사', 소: '사해행위', note: '' },
  { index: 76, 대: '민가사', 중: '민사', 소: '채무확인', note: '채무부존재' },
  { index: 77, 대: '민가사', 중: '민사', 소: '내용증명', note: '' },
  {
    index: 78,
    대: '민가사',
    중: '민사',
    소: '보전처분',
    note: '가압류,가처분'
  },
  {
    index: 79,
    대: '민가사',
    중: '민사',
    소: '계약해제',
    note: '계약해지, 계약해제'
  },
  { index: 80, 대: '민가사', 중: '민사', 소: '소유권이전등기말소', note: '' },
  { index: 81, 대: '민가사', 중: '민사', 소: '근저당말소', note: '' },
  {
    index: 82,
    대: '민가사',
    중: '민사',
    소: '강제집행',
    note: '압류추심전부, 강제집행정지, 경매'
  },
  { index: 83, 대: '민가사', 중: '민사', 소: '가등기말소', note: '' },
  { index: 84, 대: '민가사', 중: '민사', 소: '철거 등', note: '' },
  { index: 85, 대: '민가사', 중: '민사', 소: '법률자문', note: '' },
  { index: 86, 대: '민가사', 중: '민사', 소: '기타', note: '' },
  { index: 87, 대: '민가사', 중: '가사', 소: '이혼', note: '' },
  { index: 88, 대: '민가사', 중: '가사', 소: '사실혼관계해소', note: '' },
  { index: 89, 대: '민가사', 중: '가사', 소: '혼인무효', note: '' },
  { index: 90, 대: '민가사', 중: '가사', 소: '혼인취소', note: '' },
  { index: 91, 대: '민가사', 중: '가사', 소: '재산분할청구', note: '' },
  { index: 92, 대: '민가사', 중: '가사', 소: '위자료청구', note: '' },
  { index: 93, 대: '민가사', 중: '가사', 소: '인지청구', note: '' },
  { index: 94, 대: '민가사', 중: '가사', 소: '양육비청구', note: '' },
  {
    index: 95,
    대: '민가사',
    중: '가사',
    소: '친권',
    note: '친권양육권변경, 친생자부존재'
  },
  { index: 96, 대: '민가사', 중: '가사', 소: '상속', note: '' },
  { index: 97, 대: '민가사', 중: '가사', 소: '유류분', note: '' },
  {
    index: 98,
    대: '민가사',
    중: '가사',
    소: '후견',
    note: '성년후견, 한정후견'
  },
  {
    index: 99,
    대: '민가사',
    중: '가사',
    소: '상속포기한정승인',
    note: '상속포기,한정승인'
  },
  { index: 100, 대: '민가사', 중: '가사', 소: '사전처분', note: '' },
  {
    index: 101,
    대: '민가사',
    중: '가사',
    소: '개명',
    note: '개명, 성본변경'
  },
  { index: 102, 대: '민가사', 중: '가사', 소: '입양', note: '' },
  { index: 103, 대: '민가사', 중: '가사', 소: '기타', note: '' },
  {
    index: 104,
    대: '기타',
    중: '노동,산재,행정',
    소: '해고',
    note: '부당해고, 해고무효'
  },
  {
    index: 105,
    대: '기타',
    중: '노동,산재,행정',
    소: '임금퇴직금',
    note: '임금,퇴직금'
  },
  {
    index: 106,
    대: '기타',
    중: '노동,산재,행정',
    소: '근로기준법',
    note: ''
  },
  { index: 107, 대: '기타', 중: '노동,산재,행정', 소: '산재', note: '' },
  {
    index: 108,
    대: '기타',
    중: '노동,산재,행정',
    소: '행정등',
    note: '행정심판,행정소송,소청'
  },
  {
    index: 109,
    대: '기타',
    중: '노동,산재,행정',
    소: '직장내괴롭힘',
    note: ''
  },
  {
    index: 110,
    대: '기타',
    중: '노동,산재,행정',
    소: '중대재해',
    note: ''
  },
  { index: 111, 대: '기타', 중: '노동,산재,행정', 소: '기타', note: '' },
  { index: 112, 대: '기타', 중: '부동산,건설', 소: '공사대금', note: '' },
  {
    index: 113,
    대: '기타',
    중: '부동산,건설',
    소: '재개발,재건축',
    note: ''
  },
  {
    index: 114,
    대: '기타',
    중: '부동산,건설',
    소: '점유이전금지가처분',
    note: ''
  },
  { index: 115, 대: '기타', 중: '부동산,건설', 소: '건물인도', note: '' },
  { index: 116, 대: '기타', 중: '부동산,건설', 소: '분양대금', note: '' },
  { index: 117, 대: '기타', 중: '부동산,건설', 소: '하자담보', note: '' },
  { index: 118, 대: '기타', 중: '부동산,건설', 소: '기타', note: '' },
  { index: 119, 대: '기타', 중: '기업', 소: '일반소송', note: '' },
  { index: 120, 대: '기타', 중: '기업', 소: '부정경쟁', note: '' },
  { index: 121, 대: '기타', 중: '기업', 소: '영업비밀', note: '' },
  { index: 122, 대: '기타', 중: '기업', 소: '특허', note: '' },
  {
    index: 123,
    대: '기타',
    중: '기업',
    소: '지식재산권',
    note: '저작권,지식재산권,상표권'
  },
  { index: 124, 대: '기타', 중: '기업', 소: '기업자문', note: '' },
  { index: 125, 대: '기타', 중: '기업', 소: '기타', note: '' },
  { index: 126, 대: '기타', 중: '의료', 소: '손해배상(의료과실)', note: '' },
  { index: 127, 대: '기타', 중: '의료', 소: '의료자문', note: '' },
  { index: 128, 대: '기타', 중: '의료', 소: '기타', note: '' }
]

// 계약 기록 > 고객사건 정보
export const caseDetailCategory = [
  {
    key: 'caseNumber',
    value: 'caseNumber',
    label: '사건번호'
  },
  {
    key: 'caseName',
    value: 'caseName',
    label: '사건명'
  },
  {
    key: 'projectType',
    value: 'projectType',
    label: '사건구분'
  },
  {
    key: 'department',
    value: 'department',
    label: '담당부서'
  },
  {
    key: 'assignedLawyers',
    value: 'assignedLawyers',
    label: '담당변호사'
  },
  {
    key: 'assignType',
    value: 'assignType',
    label: '사건종류'
  }
]

export const dayGroups = [
  {
    value: 'daily',
    label: '일간',
    key: '2'
  },
  {
    label: '주간',
    value: 'weekly',
    key: '3'

  },
  {
    label: '월간',
    value: 'monthly',
    key: '4'
  }
]

export const graphTag = {
  CALL: 'Call Counts',
  VISIT: 'Visit Counts'
}

export const paymentCategoryOptions = [
  {
    value: 'DEPOSIT',
    label: '착수금',
    color: ''
  },
  {
    value: 'INTERIM',
    label: '중도금',
    color: ''
  },
  {
    value: 'BALANCE',
    label: '잔금',
    color: ''
  },
  {
    value: 'CONSULT',
    label: '상담료',
    color: ''
  }
]

export const extraPaymentCategoryOptions = [
  {
    value: 'CONTINGENT',
    label: '성공수금',
    color: ''
  },
  {
    value: 'EXTRA',
    label: '추가약정',
    color: ''
  },
  {
    value: 'ETC',
    label: '기타',
    color: ''
  }
]

export const allPaymentCategoryOptions = [
  {
    value: 'DEPOSIT',
    label: '착수금'
  },
  {
    value: 'INTERIM',
    label: '중도금'
  },
  {
    value: 'BALANCE',
    label: '잔금'
  },
  {
    value: 'CONSULT',
    label: '상담료'
  },
  {
    value: 'CONTINGENT',
    label: '성공수금'
  },
  {
    value: 'EXTRA',
    label: '추가약정'
  },
  {
    value: 'ETC',
    label: '기타'
  }
]

export const paymentTypeOptions = [
  {
    value: 'CARD',
    label: '카드'
  },
  {
    value: 'CASH',
    label: '현금'
  },
  {
    value: 'WIRE',
    label: '계좌이체'
  }
]

export const cycleOptions = [
  {
    value: 'DAILY',
    label: '매일'
  },
  {
    value: 'WEEKLY',
    label: '매주'
  },
  {
    value: 'MONTHLY',
    label: '매달'
  },
  {
    value: 'YEARLY',
    label: '매년'
  }]

export const currentStateOptions = [
  {
    value: '방문',
    label: '방문',
    key: '1'
  },
  {
    value: '선임',
    label: '선임',
    key: '2'
  },
  {
    value: '예약중',
    label: '예약중',
    key: '3'
  },
  {
    value: '확인중',
    label: '확인중',
    key: '4'
  },
  {
    value: '예약취소',
    label: '예약취소',
    key: '5'
  }
]

export const depositType = [
  {
    value: 'PROGRESS',
    label: '미완납',
    key: '1'
  },
  {
    value: 'COMPLETE',
    label: '완납',
    key: '2'
  },
  {
    value: 'OVERDUE',
    label: '추심',
    key: '3'
  }
]

export const expenseProofMethodOptions = [
  {
    key: 'CASHRECEIPT',
    value: 'CASHRECEIPT',
    label: '현금영수증'
  },
  {
    key: 'TAXRECEIPT',
    value: 'TAXRECEIPT',
    label: '세금계산서'
  }
]

export const projectTypeOptions = [
  {
    value: 'df4a776b-be23-4e57-9522-6e60de3f4a0f',
    label: '형사',
    key: 'df4a776b-be23-4e57-9522-6e60de3f4a0f'
  },
  {
    value: 'e9d60a0d-aa1a-469f-9fde-39fd19700f96',
    label: '민가사',
    key: 'e9d60a0d-aa1a-469f-9fde-39fd19700f96'
  },
  {
    value: 'b8d6b0c6-5c47-4478-bfec-bbfcdd4a0c70',
    label: '기타',
    key: 'b8d6b0c6-5c47-4478-bfec-bbfcdd4a0c70'
  }
]

export const customerInfo = [
  {
    key: 'name',
    value: 'name',
    label: '의뢰인명'
  },
  {
    key: 'representer',
    value: 'representer',
    label: '위임인명'
  },
  {
    key: 'phone',
    value: 'phone',
    label: '전화번호'
  },
  {
    key: 'address',
    value: 'address',
    label: '주소'
  },
  {
    key: 'email',
    value: 'email',
    label: '이메일'
  },
  {
    key: 'caseNumber',
    value: 'caseNumber',
    label: '사건번호'
  },
  {
    key: 'registrationNumber',
    value: 'registrationNumber',
    label: (
      <>
        주민번호/ <br />
        사업자등록번호
      </>
    )
  },
  {
    key: 'expectedCaseName',
    value: 'expectedCaseName',
    label: '예상사건명'
  },
  {
    key: 'opponent',
    value: 'opponent',
    label: '상대방'
  },
  {
    key: 'jurisdiction',
    value: 'jurisdiction',
    label: '관할'
  }
]

export const contractCustomerInfo = [
  {
    key: 'name',
    value: 'name',
    label: '의뢰인명'
  },
  {
    key: 'representer',
    value: 'representer',
    label: '위임인명'
  },
  {
    key: 'phone',
    value: 'phone',
    label: '전화번호'
  },
  {
    key: 'address',
    value: 'address',
    label: '주소'
  },
  {
    key: 'email',
    value: 'email',
    label: '이메일'
  },
  {
    key: 'caseNumber',
    value: 'caseNumber',
    label: '사건번호'
  },
  {
    key: 'registrationNumber',
    value: 'registrationNumber',
    label: (
      <>
        주민번호/ <br />
        사업자등록번호
      </>
    )
  },
  {
    key: 'caseName',
    value: 'caseName',
    label: '사건명'
  },
  {
    key: 'opponent',
    value: 'opponent',
    label: '상대방'
  },
  {
    key: 'jurisdiction',
    value: 'jurisdiction',
    label: '관할'
  }
]

export const customerInfoTable = [
  {
    key: '0',
    name: '홍길동',
    delegator: '김영희',
    phone: '010 0000 0000',
    address: '서울특별시 00로',
    email: '0000@mail.mail',
    registrationNumber: '0000000000000',
    caseNumber: '000가0000',
    caseName: '사건명1234',
    opponent: '홍영희',
    jurisdiction: '관할1'
  }
]

export const tmpDuplicationData = [
  {
    key: '0',
    name: '홍길동',
    delegator: '김영희',
    phone: '010 0000 0000',
    address: '서울특별시 00로',
    email: '0000@mail.mail',
    registrationNumber: '0000000000000',
    caseNumber: '000가0000',
    caseName: '사건명1234',
    opponent: '홍영희',
    jurisdiction: '관할1'
  }
]

export const timeFormat = 'HH:mm'

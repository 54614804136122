import _ from 'lodash'
import UserSelect from './UserSelect'

const UserFilter = ({ loading, filters, departments, selectedItems, selectedOptions, currentUser, initialValue }) => {
  return (
    <div>
      {_.filter(filters, ({ key }) => key !== '본사가 아닐 경우').map(({ key, options, value, defaultValue, onChangeFilter, onChangeMeta, ...props }) => {
        return (
          <UserSelect
            multiple={false}
            loading={loading}
            key='userIds'
            type='userIds'
            style={{ minWidth: 110, width: '100%' }}
            selectedOptions={selectedOptions}
            selectedItems={selectedItems}
            options={options}
            placeholder={value}
            onChangeFilter={v => onChangeFilter(v)}
            pageType='call'
            departments={departments}
            currentUser={currentUser}
            initialValue={initialValue}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default UserFilter

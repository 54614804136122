import { useEffect, useRef, useState } from 'react'
import { uuidv4 } from '../../../common/util'
import { TableActions } from './TableActions'
import { ViewMode } from '../recordCustomTable/ViewMode'
import { RowGapWrapper } from '../../../common/Theme/style'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { TotalIndicator } from '../../../timeCharge/components/common/CustomTable/TotalIndicator'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, Radio, Table, Form, Switch } from 'antd'

import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { GroupMode } from '../recordCustomTable/GroupMode'

const Container = styled.div` // 테이블 파란색 줄
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`

export const CallCustomTable = ({
  data,
  defaultColumns = [],
  groupColumns,
  typeGroupColumns,
  total,
  unit,
  disabledTableActionByKey = {
    stat: false,
    excelDownload: true,
    copyRecord: false,
    delete: false
  },
  hiddenTableActionByKey,
  viewMode,
  groupMode,
  editingKeys = [],
  scroll,
  saveAll = () => {},
  editAll = () => {},
  TableComponent = Table,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
  onChangeSelectedRowKeys = () => {},
  hiddenKeys,
  onChangeViewMode = () => {},
  onChangeGroupMode = () => {},
  handleStatViewer = () => {},
  rowSelection,
  setScratchPadList,
  form,
  handleRemoveCallRecord = () => {},
  placement,
  placementChange = () => {},
  contractStateSwitch,
  setContractStateSwitch,
  scratchpadForm,
  currentUser
}) => {
  const [columns, setColumns] = useState(defaultColumns)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [showDetail, setShowDetail] = useState(false)

  const showDetailColumns = ['SN'] // 상세보기 항목

  const handleCopyRecord = () => {
    const data = selectedRowData
    const newData = _.map(data, (v) => {
      const newKey = uuidv4()
      const item = _.cloneDeep(v)
      // 기본 필드 초기화
      const fieldsToReset = [
        'type',
        'created',
        'inputDate',
        'inputTime',
        'callType',
        'department',
        'primaryAuthor',
        'secondaryAuthor',
        'projectType',
        'projectTypeStr',
        'projectSubType',
        'address',
        'via',
        'expectedCaseName',
        'jurisdiction',
        'caseNumber',
        'manager',
        'amount',
        'content',
        'visitDepartment',
        'visitReservedDate',
        'visitReservedTime',
        'reservationStatus',
        'reservationCancelReason',
        'counselLawyers',
        'counselLawyerIds',
        'callCount',
        'visitedDate',
        'visitedTime',
        'visitor',
        'counselEmployees',
        'counselEmployeeIds',
        'nVisit',
        'nRevisit',
        'contractor',
        'assigned',
        'nAssign',
        'assignType',
        'cancelled',
        'cancelReason'
      ]
      // 초기화 적용
      fieldsToReset.forEach((field) => _.set(item, field, null))

      // 특별 처리 필드
      _.set(item, 'created', dayjs(new Date())) // 새로운 생성 시간
      _.set(item, 'key', newKey) // 고유 키 재설정
      _.set(item, 'id', newKey) // 고유 키 재설정
      _.set(item, 'amount', 0) // 금액 초기화
      _.set(item, 'createdBy', currentUser.name) // 현재 사용자 설정
      return item
    })
    const storedData = JSON.parse(localStorage.getItem('scratchPad') || '[]') // 현재 scartchpad에 저장 되어있는 데이터
    scratchpadForm.setFieldsValue({ scratchPad: [...storedData, ...newData] })
    localStorage.setItem('scratchPad', JSON.stringify([...storedData, ...newData]))
    setScratchPadList([...storedData, ...newData])

    setSelectedRowData([])
    setSelectedRowKeys([])
    onChangeSelectedRowKeys([])
  }
  const filterColumns = (placement, grouped, groupedPlacement) => {
    if (grouped === true) { // 그룹화 목록
      if (placement === 'NOTYPE') { // 온오프구분이 없는 경우
        return !showDetail ? _.reject(defaultColumns, column => _.includes(showDetailColumns, column.title)) : defaultColumns // 상세보기에 따른 항목 필터 (그룹 뷰)
      } else { // 온오프구분이 있는 경우
        const filteredColumns = defaultColumns.filter(column => column.category?.includes(placement) && column.dataIndex !== 'edit')
        return !showDetail ? _.reject(filteredColumns, column => _.includes(showDetailColumns, column.title)) : filteredColumns
      }
    } else if (placement === 'ALL') {
      return defaultColumns
    }
    return defaultColumns.filter(column => column.category?.includes(placement)) // placement === 온라인, 오프라인 column
  }

  useEffect(() => {
    if (viewMode === 'list') {
      const filteredColumns = filterColumns(placement)
      setColumns(filteredColumns)
    } else if (groupMode === 'NAME') {
      setColumns(groupColumns)
    } else {
      setColumns(typeGroupColumns)
    }
  }, [viewMode, placement, defaultColumns, groupMode])

  const EditableCell = ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              defaultValue={dataIndex}
              initialValue={dataIndex}
              rules={[{ required: true, message: `Please Input ${title}!` }]}
            >
              {inputNode}
            </Form.Item>)
          : (
              children)}
      </td>
    )
  }
  const textRef = useRef()
  const isEditing = (record) => null
  const mergedColumns = columns?.map((col) => {
    return {
      ...col,
      hidden: !showDetail && showDetailColumns.includes(col.title), // 상세보기에 따른 항목 필터 (목록 뷰)
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === 'id' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? textRef : null
      })
    }
  })

  const handleRowSelectionChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
    const selectedRowsData = data.filter(row => selectedRowKeys.includes(row.id))
    setSelectedRowData(selectedRowsData)
    onChangeSelectedRowKeys(selectedRowKeys)
  }

  const onExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : [])
  }

  const expandedRowRender = (value) => {
    const onlineData = _.filter(value.records, { type: 'ONLINE' })
    const offlineData = _.filter(value.records, { type: 'OFFLINE' })
    const visitData = _.filter(value.records, { type: 'VISIT' })
    const noType = _.filter(value.records, { type: null })
    return (
      <div>
        {onlineData.length > 0 && <Table columns={filterColumns('ONLINE', true, '온라인')} dataSource={onlineData} pagination={false} style={{ marginLeft: 40 }} />}
        {offlineData.length > 0 && <Table columns={filterColumns('OFFLINE', true, '오프라인')} dataSource={offlineData} pagination={false} style={{ marginLeft: 40 }} />}
        {visitData.length > 0 && <Table columns={filterColumns('VISIT', true, '방문')} dataSource={visitData} pagination={false} style={{ marginLeft: 40 }} />}
        {noType.length > 0 && <Table columns={filterColumns('NOTYPE', true, '')} dataSource={noType} pagination={false} style={{ marginLeft: 40 }} />}
      </div>
    )
  }
  const expandableConfig = viewMode === 'group'
    ? {
        expandedRowRender,
        defaultExpandedRowKeys: ['0']
      }
    : undefined

  const handleFormChange = (changedValues, allValues) => {
  }
  return (
    <Form
      form={form}
      onValuesChange={(changedValues, allValues) => {
        handleFormChange(changedValues, allValues)
      }}
    >
      <Container>
        <Button onClick={() => { console.log('call', form.getFieldValue()) }}>DEBUG!</Button>
        <HeaderGroup style={{ marginBottom: '10px' }}>
          <RowGapWrapper $gap={15}>
            <Radio.Group value={placement} onChange={(e) => placementChange(e.target.value)}>
              <Radio.Button value='ALL'>전체</Radio.Button>
              <Radio.Button value='ONLINE'>온라인</Radio.Button>
              <Radio.Button value='OFFLINE'>오프라인</Radio.Button>
              <Radio.Button value='VISIT'>방문</Radio.Button>
              <Radio.Button value='NASSIGN'>선임</Radio.Button>
            </Radio.Group>
            <TableActions
              disabledTableActionByKey={disabledTableActionByKey}
              hiddenTableActionByKey={hiddenTableActionByKey}
              handleStatViewer={handleStatViewer}
              handleCopyRecord={handleCopyRecord}
              handleRemoveCallRecord={handleRemoveCallRecord}
            />
            {viewMode === 'list'
              ? (
                  editingKeys.length > 0
                    ? (
                      <Button
                        type='text'
                        style={{ padding: '4px' }}
                        onClick={() => saveAll()}
                      >
                        <RowGapWrapper>
                          <SaveOutlined style={{ color: `${COLORS.purple}` }} />
                          <div>전체 저장</div>
                        </RowGapWrapper>
                      </Button>)
                    : (
                      <Button
                        type='text'
                        style={{ padding: '4px' }}
                        onClick={() => editAll(form.getFieldValue('call'))}
                        disabled={selectedRowKeys.length > 0}
                      >
                        <RowGapWrapper>
                          <EditOutlined style={{ color: selectedRowKeys.length > 0 ? `${COLORS.purple_2}` : `${COLORS.purple}` }} />
                          <div>전체 수정</div>
                        </RowGapWrapper>
                      </Button>))
              : null}
            {/* SN 항목 보기 */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Switch
                checked={showDetail}
                onChange={() => setShowDetail(!showDetail)}
                checkedChildren='상세보기'
                unCheckedChildren='상세보기'
              />
            </div>
            {/* 계약상태에 따라 항목 필터 */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Switch
                checked={contractStateSwitch}
                onChange={() => setContractStateSwitch(!contractStateSwitch)}
                checkedChildren='콜 기록 진행중'
                unCheckedChildren='전체보기'
              />
            </div>
          </RowGapWrapper>
          <Spacer />
          {viewMode !== 'list'
            ? (
              <GroupMode
                groupMode={groupMode}
                hiddenKeys={hiddenKeys}
                onChangeGroupMode={onChangeGroupMode}
              />)
            : null}
          <ViewMode
            viewMode={viewMode}
            hiddenKeys={hiddenKeys}
            onChangeViewMode={onChangeViewMode}
          />
          <TotalIndicator total={total} unit={unit} loading={loading} />
        </HeaderGroup>
        <Form.List name='call'>
          {(fields) => {
            return (
              <TableComponent
                rowKey='key'
                columns={mergedColumns}
                expandable={expandableConfig}
                onExpand={onExpand}
                expandedRowKeys={expandedRowKeys}
                dataSource={fields.map(field => ({ ...field, field, ...form.getFieldValue('call')[field.name] }))}
                pagination={false}
                size='middle'
                rowSelection={rowSelection !== null && viewMode === 'list' // group에서는 selection이 보이지 않게 설정
                  ? ({
                      type: 'checkbox',
                      selectedRowKeys,
                      getCheckboxProps: (record) => {
                        const disable = (editingKeys?.findIndex((item) => record.key === item)) !== -1
                        return {
                          disabled: disable
                        }
                      },
                      onChange: handleRowSelectionChange
                    })
                  : undefined}
                scroll={{
                  y: scroll
                }}
                components={{
                  body: {
                    cell: EditableCell
                  }
                }}

              />
            )
          }}
        </Form.List>
      </Container>
    </Form>
  )
}

import { RowGapWrapper } from '../../../common/Theme/style'
import { DateRangeFilter } from '../filters/DateRangeFilter'

import { Button, InputNumber } from 'antd'
import _ from 'lodash'
import MultipleSelect from '../../../call/components/common/MultipleSelect'
import { useDepartmentList } from '../../../call/hooks/common/filter/useDepartmentList'
import { ProjectTypeModal } from '../../../call/components/recordCustomTable/ProjectTypeModal'
import { useState } from 'react'
import { projectTypesData } from '../../../mockdata'

// 계약 기록 > 회수/미수금액 필터
const ContractRecordFilter = ({
  viewMode,
  loading,
  meta,
  filters,
  onChangeMeta = () => {},
  onResetFilterAndGroup = () => {},
  onSelectFilter,
  mode = 'multiple',
  searchParams,
  pageType = null,
}) => {
  const { departments } = useDepartmentList(null);
  const [projectTypeModalOpen, setProjectTypeModalOpen] = useState(false)
  const [projectTypeSelection, setProjectTypeSelection] = useState(null)

  const handlProjectTypeOk = (value) => {
    setProjectTypeSelection(value)
    setProjectTypeModalOpen(false)
    onChangeMeta('filter', 'projectType', value)
  }
  const handleProjectTypeCancel = () => {
    setProjectTypeModalOpen(false)
  }
  return (
    <div>
      <RowGapWrapper style={{ alignItems: 'center' }}>
        {(viewMode !== 'calendar' && pageType !== 'overdue') && (
          <DateRangeFilter
            value={meta.filter.datetimeRange}
            onCalendarChange={(v) => onChangeMeta('filter', 'datetimeRange', v)}
          />
        )}
        {_.filter(filters, ({ key }) => key !== '본사가 아닐 경우').map(({ key, selectedOptions, selectedItems, options, value, onChangeFilter, ...props }) => {
          if (key === 'projectType') {
            return (
              <Button
                key={key}
                onClick={() => setProjectTypeModalOpen(true)}
                style={{ minWidth: 200, display: 'flex', justifyContent: 'left' }}
              >
                {_.isEmpty(meta.filter.projectType) ? <span style={{ color: '#bcbcbc' }}>사건구분</span> : meta.filter.projectType}
              </Button>
            )
          }

          return key !== 'price'
            ? (
              <MultipleSelect
                multiple={false}
                loading={loading}
                key={key}
                type={key}
                style={{ minWidth: 200 }}
                selectedOptions={selectedOptions}
                selectedItems={selectedItems}
                filters={filters}
                options={options}
                placeholder={value}
                onChangeFilter={(v) => onChangeFilter(v)}
                departments={departments}
                {...props}
              />)
            : (
              <InputNumber
                key={key}
                changeOnWheel
                placeholder='0'
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                addonAfter='원'
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                style={{ minWidth: 120 }}
              />)
        })}
        <Button onClick={onResetFilterAndGroup}>필터 초기화</Button>
      </RowGapWrapper>

      {/* Modal for projectType */}
      <ProjectTypeModal
        pageType='filter'
        visible={projectTypeModalOpen}
        onOk={(value) => handlProjectTypeOk(value)}
        onCancel={handleProjectTypeCancel}
        dataSource={projectTypesData}
        setProjectTypeSelection={setProjectTypeSelection}
      />
    </div>
  )
}

export default ContractRecordFilter

import { RowGapWrapper } from '../../../common/Theme/style'
import { SearchParamsFilter } from '../../../call/components/filter/SearchParamsFilter'

import styled from 'styled-components'
import { Select, Space } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'

const CaseTagSelect = styled(Select)`
  & {
    width: 100%;
  }
  & .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`
const UserSelect = ({ currentUser = null, initialValue, departments, pageType, multiple, mode, loading, type, options = [], selectedItems, selectedOptions, placeholder, style = {}, onChangeDepartmentFilter = () => {}, onChangeFilter = () => {}, onSelectChange = () => {}, selectedDepartmentOptions, ...props }) => {
  const changeValuesToLabels = (selectedValues) => {
    return _.map(selectedValues, (value) => {
      const matchedOption = _.find(options, { value })
      return matchedOption?.label
    })
  }
  const getInitialValue = () => {
    if (initialValue) return initialValue
    if (currentUser) return changeValuesToLabels([currentUser.id])
    return []
  }
  const [currentItem, setCurrentItem] = useState(getInitialValue())
  // initialValue가 변경될 때 반영
  useEffect(() => {
    setCurrentItem(getInitialValue())
  }, [initialValue, currentUser])

  // selectedItems이 변경될 때 반영
  useEffect(() => {
    if (selectedItems?.length > 0) {
      setCurrentItem(changeValuesToLabels(selectedItems))
    } else {
      setCurrentItem(null)
    }
  }, [selectedItems])
  if (type === 'userIds' || type === 'assignedLawyerId') {
    return (
      <Select
        allowClear
        mode={mode}
        value={currentItem}
        popupMatchSelectWidth={570}
        placeholder={pageType ? '직원' : '담당변호사'}
        onClear={onChangeFilter}
        dropdownRender={menu => {
          return (
            <>
              <RowGapWrapper>
                <SearchParamsFilter
                  loading={loading}
                  selectedItems={selectedItems}
                  onChange={e => onChangeFilter(e)} // 고급 검색의 직원
                  onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
                  selectedDepartmentOptions={selectedDepartmentOptions}
                  options={options}
                  multiple={multiple} // 직원 여러명 선태 가능 여부
                  departments={departments}
                  {...props}
                />
              </RowGapWrapper>
            </>
          )
        }}
      />
    )
  } else {
    return (
      <Space direction='vertical'>
        <CaseTagSelect
          allowClear
          style={style}
          value={selectedItems}
          placeholder={placeholder}
          onChange={onChangeFilter}
          options={options}
        />
      </Space>
    )
  }
}

export default UserSelect

import { useEffect, useState } from 'react'

import { RowContainer } from '../../../common/Theme/style'
import { CustomSearch } from '../../../common/components/CustomSearch'
import { VirtualList } from '../../../timeCharge/components/filters/VirtualList'
import { FilterSelect } from '../../../timeCharge/components/settings/DepartmentFilter'
import { UserInputCustomEmptyMessage } from '../../../timeCharge/components/timesheetForm/UserInputCustomEmptyMessage'
import { useUserGroupList } from '../../../timeCharge/hooks/apollo/useUserGroupList'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Button, List, Spin, Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import _ from 'lodash'
import UserIcon from '../../../common/components/UserIcon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .ant-btn {
    font-size: 12px;
  }
`
const Filters = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
`
const ListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-style: solid;
  border-width: ${props => props.$bordered ? 0.5 : 0}px;
  color: lightGrey;
  border-radius: 10px;
  > * {
    padding: 10px;
  }
`
const ListTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainFilter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const CaseOpenClosefilterOptions = styled.div`
  display: flex;
  justify-content: right;
  gap: 15px;
  padding-bottom: 10px;
`

const checkedProps = { color: COLORS.primaryColor, borderColor: COLORS.primaryColor }
// 진행사건 필터 목록
const FilterGroup = ({ listTitle, virtualListRef, isVirtualList, options, title, selectedItems, onCheckRow, bordered, listHeight = 400, refetch, loading, dataParser, emptyText = '데이터가 존재하지 않습니다.', usersListDataLoading, searchedUserListDataLoading }) => {
  return (
    <ListContainer $bordered={bordered}>
      {title
        ? (
          <ListTitleContainer>
            <Typography.Title
              style={{ margin: 0, color: COLORS.primaryColor }}
              level={5}
              type='secondary'
            >
              {title}
            </Typography.Title>
          </ListTitleContainer>)
        : null}
      {/* {listTitle === '작성자' && !_.isEmpty(options)
        ? (
          <Button onClick={handleSelectAll} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600', marginBottom: '5px' }}>
            {selectedItems.length === options.length ? '전체 해제' : '전체 선택'}
          </Button>)
        : null} */}
      {_.isEmpty(options)
        ? <UserInputCustomEmptyMessage text={emptyText} usersListDataLoading={usersListDataLoading} searchedUserListDataLoading={searchedUserListDataLoading} />
        : isVirtualList
          ? (
            <VirtualList
              ref={virtualListRef}
              height={listHeight}
              refetch={refetch}
              loading={loading}
              dataParser={dataParser}
              renderItem={({ label, value }) => {
                const isChecked = _.includes(selectedItems, value)
                return (
                  <div style={{ padding: '8px 0px' }}>
                    <Button
                      shape='round'
                      key={value}
                      style={{ width: '100%', ...isChecked ? checkedProps : {}, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      onClick={() => onCheckRow(isChecked, value)}
                    >
                      <div style={{ display: 'flex' }}>
                        {label}
                      </div>
                      {isChecked && <CheckOutlined color={COLORS.primaryColor} />}
                    </Button>
                  </div>
                )
              }}
            />)
          : (
            <List
              size='small'
              bordered={false}
              style={{ overflow: 'auto', height: listHeight, width: '200px' }}
              dataSource={options.map(({ label, value, group, color }) => {
                const isChecked = _.includes(selectedItems, value)
                return (
                  <Button
                    shape='round'
                    key={value}
                    style={{ width: '100%', ...isChecked ? checkedProps : {}, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    onClick={() => onCheckRow(isChecked, value)}
                  >
                    <UserIcon group={group?.name} name={label} filter />
                    {isChecked && <CheckOutlined color={COLORS.primaryColor} />}
                  </Button>
                )
              })}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />)}
    </ListContainer>
  )
}

export const SearchParamsFilter = ({
  virtualListRef,
  isVirtualList,
  options = [],
  selectedItems = [],
  caseOpenClosefilterOptions = [],
  onChange,
  hideSearch,
  multiple = true,
  refetch = () => {},
  loading,
  dataParser,
  searchQuery,
  emptyText,
  onSearch,
  persistElement,
  usersListDataLoading,
  searchedUserListDataLoading,
  selectedDepartmentOptions = [],
  onChangeDepartmentFilter,
  departments
}) => {
  const [selectedEmployees, setSelectedEmployees] = useState([])
  // 검색하다가 껐을 경우 다시 켰을때 앞전 검색어가 남아있지 않게 수정
  useEffect(() => {
    return () => {
      onSearch && onSearch(null)
    }
  }, [])

  const onCheckDepartemntSelection = (rowKeys) => {
    updateFilters(rowKeys)
  }
  const updateFilters = (newDepartmentIds) => {
    onChangeDepartmentFilter(newDepartmentIds)
  }

  const {
    userGroups
  } = useUserGroupList() // 권한 그룹

  const onCheckRow = (isChecked, value) => {
    if (Array.isArray(value)) {
      if (isChecked) {
        onChange([])
      } else {
        onChange(value)
      }
    } else {
      if (multiple) {
        if (isChecked) {
          onChange(_.filter(selectedItems, v => v !== value))
        } else {
          onChange([...selectedItems, value])
        }
      } else {
        if (isChecked) {
          onChange([])
        } else {
          onChange([value])
        }
      }
    }
  }

  const filterSearch = true

  return (
    <Container>
      <CaseOpenClosefilterOptions>
        {caseOpenClosefilterOptions}
      </CaseOpenClosefilterOptions>
      <Filters>
        <MainFilter>
          {hideSearch ? null : <CustomSearch defaultValue={searchQuery} style={{ width: '100%' }} onSearch={onSearch} filterSearch={filterSearch} />}
          <RowContainer>
            <FilterSelect // 필터전체
              scroll={{ y: 400 }}
              departments={_.map(departments, department => ({ name: _.get(department, ['name']), key: _.get(department, ['id']) }))}
              employees={_.map(userGroups, userGroup => ({ key: userGroup.id, allocation: userGroup?.name }))}
              onChangeEmployeesSelection={rowKeys => setSelectedEmployees(rowKeys)}
              selectedDepartmentIds={selectedDepartmentOptions} // 선택된 부서 넘겨주기
              selectedEmployees={selectedEmployees}
              onChangeSelection={(rowKeys) => onCheckDepartemntSelection(rowKeys)} // 부서 필터 선택
            />
            {/* 작성자 필터의 직원 목록 */}
            {
              loading
                ? (
                  <div style={{ paddingLeft: '100px' }}><Spin /></div>)
                : (
                  <FilterGroup
                    listTitle={persistElement?.props.text}
                    isVirtualList={isVirtualList}
                    virtualListRef={virtualListRef}
                    dataParser={dataParser}
                    refetch={refetch}
                    loading={loading}
                    listHeight={hideSearch ? 400 + 31.992 + 10 : undefined}
                    options={options} // 부서에 따른 직원 목록
                    selectedItems={selectedItems}
                    onCheckRow={onCheckRow}
                    emptyText={emptyText}
                    bordered={false}
                    usersListDataLoading={usersListDataLoading}
                    searchedUserListDataLoading={searchedUserListDataLoading}
                  />)
            }
          </RowContainer>
        </MainFilter>
      </Filters>
    </Container>
  )
}

import { useState } from 'react'
import _ from 'lodash'
import { useUserList } from './apollo/useUserList'
import { useDepartmentList } from '../../call/hooks/common/filter/useDepartmentList'
import { useRecordFilterSearchParams } from '../../call/hooks/filter/callRecord/useRecordFilterSearchParams'

export const useUserFilter = (form, index, initialValue, dataType, pageType) => {
  const [openStatusByKey, setOpenStatusByKey] = useState({}) // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const [selectedItems, setSelectedItems] = useState()

  const {
    meta,
    searchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup
  } = useRecordFilterSearchParams()
  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)

  const convertToOptions = ({ id, name, group, ...v }) => {
    return {
      label: name,
      value: id,
      key: id,
      group
    }
  }

  const {
    loading,
    users
  } = useUserList(
    userSearchQuery
  )
  const findUserById = (users, id) => (id ? _.find(users, { id }) : null)
  const filters = [
    {
      key: 'userIds',
      value: '담당변호사',
      options: _.map(
        _.filter(
          users,
          user => {
            return _.isEmpty(meta.filter.caseIds) ? _.intersection(selectedDepartmentIds, _.map(_.get(user, ['departments'], []), 'id')).length > 0 : user
          }
        ),
        v => convertToOptions(v, 'userIds')
      ),
      selectedOptions: _.map(users, v => convertToOptions(v, 'userIds')),
      selectedDepartmentOptions: selectedDepartmentIds,
      // searchQuery: userSearchQuery,
      onSearch: v => setUserSearchQuery(v),
      emptyText: '선택 가능한 사용자가 없습니다.'
      // departmentOptions: _.map(departments, 'id')
    }
  ].map(v => ({
    ...v,
    // selectedItems: _.get(meta, ['filter', v.key], ''),
    selectedItems,
    selectedOptions: _.isEmpty(v.selectedOptions) ? v.options : v.selectedOptions,
    onChangeFilter: (value = []) => {
      setSelectedItems(value)
      const user = findUserById(users, value[0])

      if (pageType === 'call') {
        // 사용자 id로 사용자 겁색
        form.setFieldValue(['call', index, dataType], user)
      } else if (pageType === 'scratchPad') {
        form.setFieldValue(['scratchPad', index, dataType], user)
        const newData = form.getFieldValue('scratchPad')
        localStorage.setItem('scratchPad', JSON.stringify(newData))
      } else {
        form.setFieldValue([dataType], user)
      }
    },
    onChangeDepartmentFilter: (value) => { // 작성자 > 부서 목록 변경시 업데이트
      setSelectedDepartmentIds(value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }))
  return {
    loading,
    meta,
    filters,
    // caseNumbers,
    // users,
    // closedTag,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments,
    selectedItems,
    setSelectedItems
  }
}

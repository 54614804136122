import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { useRerservationStatusTitleAdder } from '../../../common/hooks/settings/reservationStatus/useRerservationStatusTitleAdder'
import ReservationStatusTitleAdder from '../../components/settings/reservationStatus/ReservationStatusTitleAdder'
import { useCallRerservationStatusList } from '../../../call/hooks/callRecord/useCallRerservationStatusList'

import styled from 'styled-components'
import _ from 'lodash'

const Container = styled.div`
  width: 100%;
`
const ManageReservationStatusContainer = () => {
  const { callReservationStatus, loading } = useCallRerservationStatusList()
  const {
    onAddCallReservationStatus,
    onEditCallReservationStatus,
    onRemoveCallReservationStatus
  } = useRerservationStatusTitleAdder()

  if (loading) {
    return <Container />
  }

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer>
          <HeaderTitle title='예약상황 항목 관리' />
          <Spacer $flex={5} />
        </HeaderContainer>
      )}
      content={(
        <Container>
          <ReservationStatusTitleAdder
            initDataSource={_.map(callReservationStatus, ({ ...v }) => ({ key: v.id, ...v }))}
            onAddCallReservationStatus={onAddCallReservationStatus}
            onEditCallReservationStatus={onEditCallReservationStatus}
            onRemoveCallReservationStatus={onRemoveCallReservationStatus}
          />
        </Container>
      )}
    />
  )
}

export default ManageReservationStatusContainer

import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CommentForm } from './CommentForm'
import { DepositForm } from './DepositForm'
import { ContractForm } from './ContractForm'
import { projectTypesData } from '../../../mockdata'
import { ExtraContractForm } from './ExtraContractForm'
import { getCurrentUser } from '../../../timeCharge/apollo/queries'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { useUserList } from '../../../common/hooks/apollo/useUserList'
import { InfoCard } from '../../components/contractRecordDetail/InfoCard'
import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { useDepartmentList } from '../../hooks/common/filter/useDepartmentList'
import { useProjectTypes } from '../../../timeCharge/hooks/apollo/useProjectTypes'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { ProjectTypeModal } from '../../components/recordCustomTable/ProjectTypeModal'
import { useContractRecord } from '../../hooks/apollo/contractRecord/useContractRecord'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { useContractRecordClose } from '../../hooks/contractRecord/useContractRecordClose'
import { ContractTotalRecordCard } from '../../components/contractRecordDetail/ContractTotalRecordCard'
import { caseDetailCategory, contractCustomerInfo, customerInfo, tmpDuplicationData } from '../../config'
import { HeaderContainer, PaymentHeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { useContractRecordAddEditRemove } from '../../hooks/contractRecord/useContractRecordAddEditRemove'
import { useContractorInfoColumns } from '../../hooks/contractRecord/contractRecordDetail/useContractorInfoColumns'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'
import { ColumnGapWrapper, InputTitle, NarrowCustomCard, ProjectTypeCustomSearchContainer, RowGapWrapper } from '../../../common/Theme/style'

import { useQuery } from '@apollo/client'
import COLORS from '../../../common/Theme/colors'
import styled from 'styled-components'
import _ from 'lodash'
import { Button, Modal, Table, Tabs, Form, Input, Select } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { FindUser } from '../../hooks/contractRecord/table/FindUser'
dayjs.locale('ko')

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0 12px;
  }
`
const TableCategory = styled.div`
  width: 100px;
  color: ${COLORS.black_65};
  display: flex;
  align-items: center;
`
const HeaderSpacer = styled.div`
  box-shadow: 0px 2px 3px #DFDFDF86;
`
const StyledEditButton = styled(Button)`
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 999;
`
export const ContractRecordDetailContainer = () => {
  const { id } = useParams()
  const [pageDelete, setPageDelete] = useState(false)
  /*
  계약 기록 데이터
*/
  const {
    loading,
    contractRecordData,
    refetch
  } = useContractRecord(
    id
  )
  const { departments } = useDepartmentList()
  const totalReceivedAmount = (data) => {
    if (data !== undefined && data.length > 0) {
      return (
        data.reduce(
          (sum, deposit) => sum + (deposit.receivedAmount || 0),
          0
        )
      )
    } else {
      return 0
    }
  }
  const formattedData = (value) => {
    return (
      _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
        key: periodicId,
        id: records[0].id,
        periodicId,
        totalDueAmount: _.sumBy(records, 'dueAmount'),
        totalReceivedAmount: totalReceivedAmount(records[0].deposits),
        category: records[0].category,
        periodic: records[0].periodic,
        dueDate: records[0].dueDate,
        dueAmount: records[0].dueAmount,
        condition: records[0].condition,
        payments: _.map(records, (record) => ({
          key: record.id,
          dueDate: record.dueDate,
          dueAmount: record.dueAmount,
          deposits: record.deposits,
          category: record.category,
          condition: record.condition
        }))
      })))
  }
  useEffect(() => {
    if (!loading) {
      const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
      const paymentComments = _.get(contractRecordData, 'paymentComments', [])
      const restructureContracts = (contracts) => {
        return contracts.reduce((acc, contract) => {
          acc[contract.periodicId] = contract
          return acc
        }, {})
      }
      /* 계약 총 금액 */
      setContractTotal(_.sumBy(paymentRecords, 'dueAmount'))
      /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
      const contracts = _.filter(paymentRecords, record =>
        ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
      )
      const contractsData = restructureContracts(formattedData(contracts))

      const extraContracts = _.filter(paymentRecords, record =>
        ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
      )
      const extracontractData = restructureContracts(formattedData(extraContracts))
      /* 입금 내역 */
      const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')

      const initialValues = {
        contract: contractsData,
        extraContract: extracontractData,
        commentList: paymentComments,
        depositRecordList: depositsArray
      }
      form.setFieldsValue(initialValues)
    }
  }, [contractRecordData])

  const [contractDataSource, setContractDataSource] = useState([]) // 계약 목록
  const [commentContractData, setCommentContractData] = useState([])
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [currentTab, setCurrentTab] = useState('callerInfo')
  const [closeContract, setCloseContract] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenContractorInfo, setIsModalOpenContractorInfo] = useState(false)
  const [isModalOpenCaseInfo, setIsModalOpenCaseInfo] = useState(false)
  const [receivedAmount, setReceivedAmount] = useState(0) // 회수 금액
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [form] = Form.useForm() // 수정중인데 나갈 경우, 경고창
  const formStatus = getRecordFormStatus()
  const prevStatus = useRef(formStatus)
  const [contractTotal, setContractTotal] = useState(0)
  const [formEditable, setFormEditable] = useState(true)
  const [projectTypeModalOpen, setProjectTypeModalOpen] = useState(false)
  const [projectTypeSelection, setProjectTypeSelection] = useState(null)
  const [filteredInfo, setFilteredInfo] = useState({})
  const [results, setResults] = useState(null)
  const [middleCategoryFilters, setMiddleCategoryFilters] = useState([
    { text: '성범죄', value: '성범죄' },
    { text: '경제범죄', value: '경제범죄' },
    { text: '강력범죄', value: '강력범죄' },
    { text: '교통범죄', value: '교통범죄' },
    { text: '마약범죄', value: '마약범죄' },
    { text: '도박', value: '도박' },
    { text: '학교폭력', value: '학교폭력' },
    { text: '일반형사', value: '일반형사' },
    { text: '군형사', value: '군형사' },
    { text: '소년사건', value: '소년사건' },
    { text: '고소,고발', value: '고소,고발' },
    { text: '민사', value: '민사' },
    { text: '가사', value: '가사' },
    { text: '노동,산재,행정', value: '노동,산재,행정' },
    { text: '부동산,건설', value: '부동산,건설' },
    { text: '기업', value: '기업' },
    { text: '의료', value: '의료' }
  ])
  const { projectTypesLoading, projectTypes } = useProjectTypes()

  useEffect(() => {
    sumReceivedAmount() // 회수금액
    setFormEditable(_.get(contractRecordData, 'deleted') === null && _.get(contractRecordData, 'closed') === null)
  }, [depositDataSource, contractRecordData])

  const {
    cancelModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  } = useSavingContractRecordDetailForm(
    contractRecordData,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete,
    setEditMode,
    setContractDataSource,
    contractDataSource
  )

  const {
    onRemoveContractRecord
  } = useContractRecordAddEditRemove(
    refetch
  )

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal
  } = useConfirmModal(
    onRemoveContractRecord
    // onDeleteCallRecord,
    // onDeleteContract,
    // onDeleteContractItem,
    // onDeleteComment,
    // onDeletePaymentRecord
  )

  const {
    callDataSource,
    isDuplicateCaseNumber,
    isDuplicateOpponent,
    duplicationTableColumns
  } = useContractorInfoColumns(
    setProjectTypeModalOpen,
    projectTypeSelection
  ) // 콜 고객 정보, 계약자 정보

  const {
    onCloseContractRecord,
    onUncloseContractRecord
  } = useContractRecordClose(refetch)
  const {
    onEditContractRecord
  } = useContractRecordAddEditRemove(refetch)
  // 종결 버튼 상태 확인
  const handleClose = (e) => {
    if (pageStatusClosed) {
      onCloseContractRecord(id)
    } else {
      onUncloseContractRecord(id)
    }
    setCloseContract(!closeContract)
  }
  const onOkDeleteConfirmModal = () => {
    setPageDelete(true)
    onRemoveContractRecord(id)
  }
  const handleOk = () => {
    setIsModalOpenContractorInfo(false)
    setIsModalOpenCaseInfo(false)
    setPageDelete(true)
    contractUserInfoForm.submit()
    contractInfoForm.submit()
  }
  const handleCancel = () => {
    setPageDelete(false)
    setIsModalOpenContractorInfo(false)
    setIsModalOpenCaseInfo(false)
  }
  const handleModal = (value) => {
    if (value === 'duplication') {
      setIsModalOpen(true)
    } else if (value === 'contractor') {
      setIsModalOpenContractorInfo(true)
    } else if (value === 'case') {
      setIsModalOpenCaseInfo(true)
    }
  }

  const rowSelection = {

    onChange: (selectedRowKeys, selectedRows) => {
      const targetName = _.get(selectedRows[0], '소', '')
      const matchedItem = _.find(projectTypes, { name: targetName })

      contractInfoForm.setFieldsValue({
        projectType: matchedItem
      })
    }
  }
  const { data } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(data, ['CurrentUser'])

  // 회수금액
  const sumReceivedAmount = () => {
    const formData = form.getFieldValue('depositRecordList')
    const sum = _.sumBy(formData, 'receivedAmount')
    setReceivedAmount(sum)
  }

  const onFinish = (values) => {
    onEditContractRecord(id, values)
  }
  const { users } = useUserList()
  const [contractUserInfoForm] = Form.useForm()
  const [contractInfoForm] = Form.useForm()
  const columns = [
    {
      title: '대분류',
      dataIndex: '대',
      key: '대',
      width: 200,
      filters: [
        { text: '형사', value: '형사' },
        { text: '민가사', value: '민가사' },
        { text: '기타', value: '기타' }
      ],
      onFilter: (value, record) => record.대.includes(value),
      filteredValue: filteredInfo.대 || null
      // sorter: (a, b) => a.firstCategory.length - b.firstCategory.length,
      // sortOrder: sortedInfo.columnKey === 'firstCategory' ? sortedInfo.order : null,
      // ellipsis: true
    },
    {
      title: '중분류',
      dataIndex: '중',
      key: '중',
      width: 200,
      filters: middleCategoryFilters,
      onFilter: (value, record) => record.중.includes(value),
      filteredValue: filteredInfo.중 || null
      // sorter: (a, b) => a.age - b.age,
      // sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
      // ellipsis: true
    },
    {
      title: '소분류',
      dataIndex: '소',
      key: '소',
      width: 200
    },
    {
      title: '설명',
      dataIndex: 'note',
      key: 'note'
    }
  ]
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    // Update middle category filters based on '대분류'
    const selectedMainCategory = filters['대'] ? filters['대'][0] : null

    if (selectedMainCategory === '형사') {
      setMiddleCategoryFilters([
        { text: '성범죄', value: '성범죄' },
        { text: '경제범죄', value: '경제범죄' },
        { text: '강력범죄', value: '강력범죄' },
        { text: '교통범죄', value: '교통범죄' },
        { text: '마약범죄', value: '마약범죄' },
        { text: '도박', value: '도박' },
        { text: '학교폭력', value: '학교폭력' },
        { text: '일반형사', value: '일반형사' },
        { text: '군형사', value: '군형사' },
        { text: '소년사건', value: '소년사건' },
        { text: '고소,고발', value: '고소,고발' }
      ])
    } else if (selectedMainCategory === '민가사') {
      setMiddleCategoryFilters([
        { text: '민사', value: '민사' },
        { text: '가사', value: '가사' }
      ])
    } else if (selectedMainCategory === '기타') {
      setMiddleCategoryFilters([
        { text: '노동,산재,행정', value: '노동,산재,행정' },
        { text: '부동산,건설', value: '부동산,건설' },
        { text: '기업', value: '기업' },
        { text: '의료', value: '의료' }
      ])
    } else {
      setMiddleCategoryFilters([
        { text: '성범죄', value: '성범죄' },
        { text: '경제범죄', value: '경제범죄' },
        { text: '강력범죄', value: '강력범죄' },
        { text: '교통범죄', value: '교통범죄' },
        { text: '마약범죄', value: '마약범죄' },
        { text: '도박', value: '도박' },
        { text: '학교폭력', value: '학교폭력' },
        { text: '일반형사', value: '일반형사' },
        { text: '군형사', value: '군형사' },
        { text: '소년사건', value: '소년사건' },
        { text: '고소,고발', value: '고소,고발' },
        { text: '민사', value: '민사' },
        { text: '가사', value: '가사' },
        { text: '노동,산재,행정', value: '노동,산재,행정' },
        { text: '부동산,건설', value: '부동산,건설' },
        { text: '기업', value: '기업' },
        { text: '의료', value: '의료' }
      ])
    }
  }
  const onSearch = (value) => {
    if (value.trim() === '') {
      setResults(projectTypesData)
      return
    }
    const filteredResults = projectTypesData.filter((item) => {
      return (
        item.note.includes(value) ||
        item.대.includes(value) ||
        item.중.includes(value) ||
        item.소.includes(value)
      )
    })
    setResults(filteredResults)
  }

  // 콜 고객 정보/계약자 정보 탭
  const InfoContainer = () => {
    let currentData = _.get(contractRecordData, ['callRecord'])
    let currentColumn = customerInfo
    if (currentTab !== 'callerInfo') {
      currentData = contractRecordData
      currentColumn = contractCustomerInfo
    }
    return (
      <>
        <ColumnGapWrapper $gap={15}>
          {currentColumn.map(({ key, label, value }) => {
            // 수정: 중복 기능 구현 필요
            let content = _.get(currentData, [value])
            if (_.isObject(content)) {
              content = content.name
            }
            return (
              <RowGapWrapper key={key}>
                <TableCategory>{label}</TableCategory>
                {/* {currentTab === 'contractorInfo'
                  ? (
                    <>
                      {key === 'opponent' && ( // 상대방
                        <StyledTag color='red' onClick={() => handleModal('duplication')}>
                          중복
                        </StyledTag>)}
                      <Input
                        defaultValue={data}
                        placeholder='내용을 입력해주세요.'
                        min={0}
                        style={{
                          width: 160
                        }}
                        // onChange={(value) => handleFieldChange(key, 'receivedAmount', value)}
                      />
                    </>)
                  : (
                    <div>{data}</div>)} */}
                <div>{content}</div>
              </RowGapWrapper>
            )
          })}
        </ColumnGapWrapper>
        {/* 계약자 정보 수정 모달 */}
        <Modal
          width={1700}
          height={800}
          open={isModalOpenContractorInfo}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='저장'
          cancelText='취소'
        >
          <Form form={contractUserInfoForm} initialValues={contractRecordData} onFinish={values => onFinish(values)}>
            <h4>계약자 정보</h4>
            <ColumnGapWrapper $gap={15}>
              <Table
                pagination={false}
                columns={callDataSource}
                dataSource={[contractRecordData]}
              />
              {isDuplicateCaseNumber &&
                <>
                  <h4>중복된 사건 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
              {isDuplicateOpponent &&
                <>
                  <h4>중복된 상대방 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
            </ColumnGapWrapper>
          </Form>
        </Modal>
        {/* 사건 정보 수정 모달 */}

      </>
    )
  }

  const tabItems = [
    {
      key: 'callerInfo',
      label: '콜 고객 정보',
      children: <InfoContainer tab='call' />
    },
    {
      key: 'contractorInfo',
      label: '계약자 정보',
      children: <InfoContainer tab='contract' />
    }
  ]

  const onChange = (key) => {
    setCurrentTab(key)
  }

  useEffect(() => {
    if (!cancelModalOpen && !pageDelete) {
      prevStatus.current = formStatus
    }
  }, [cancelModalOpen, pageDelete])

  const pageStatus = _.get(contractRecordData, 'deleted') === null && (editMode === false)
  const pageStatusClosed = _.get(contractRecordData, 'closed') === null

  const handlProjectTypeOk = () => {
    const updatedScratchPad = contractUserInfoForm.getFieldValue() || []
    form.setFieldsValue(updatedScratchPad)
    setProjectTypeModalOpen(false)
  }
  const handleProjectTypeCancel = () => {
    setProjectTypeModalOpen(false)
  }
  const transformUser = (user) => {
    return {
      value: user?.id,
      label: user?.name
    }
  }
  return (
    <>
      <ProjectTypeModal
        pageType='scratchpad'
        visible={projectTypeModalOpen}
        onOk={() => handlProjectTypeOk()}
        onCancel={handleProjectTypeCancel}
        dataSource={projectTypesData}
        form={form}
        setProjectTypeSelection={setProjectTypeSelection}
        projectTypes={projectTypes}
      />
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onOkDeleteConfirmModal}
        onCancel={onCloseModal}
      />
      <BlockModal
        title='수정을 취소하시겠습니까?'
        open={cancelModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <Modal
        width={1200}
        height={800}
        open={isModalOpenCaseInfo}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='저장'
        cancelText='취소'
      >
        {/* <Button onClick={() => { console.log('contract', contractInfoForm.getFieldValue()) }}>DEBUG!</Button> */}
        <Form form={contractInfoForm} initialValues={contractRecordData} onFinish={values => onFinish(values)}>
          <h4>계약자 정보</h4>
          <ColumnGapWrapper $gap={15}>
            {
              caseDetailCategory.map((data) => {
                if (data.key === 'projectType') {
                  return (
                    <RowGapWrapper key={data.key} $gap={10}>
                      <InputTitle style={{ marginTop: '10px' }}>{data.label}</InputTitle>
                      <ColumnGapWrapper>
                        <ProjectTypeCustomSearchContainer>
                          <Input.Search
                            placeholder='검색어를 입력해주세요.'
                            onSearch={onSearch}
                            allowClear
                          />
                        </ProjectTypeCustomSearchContainer>
                        <Form.Item
                          name={['projectType', 'id']}
                          key={data.key}
                        >
                          <Table
                            columns={columns}
                            dataSource={results || projectTypesData}
                            // onChange={handleChange}
                            rowKey='index'
                            pagination={false}
                            scroll={{ y: 200 }}
                            rowSelection={{
                              type: 'radio',
                              ...rowSelection
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                        </Form.Item>
                      </ColumnGapWrapper>
                    </RowGapWrapper>
                  )
                } else if (data.key === 'department') {
                  return (
                    <RowGapWrapper key={data.key} $gap={10} style={{ alignItems: 'center' }}>
                      <InputTitle>{data.label}</InputTitle>
                      <Form.Item
                        name={['department', 'id']}
                        style={{ width: '100%' }}
                      >
                        <Select
                          placeholder='부서'
                          options={departments.map(v => ({ label: v.name, value: v.id }))}
                          allowClear
                        />
                      </Form.Item>
                    </RowGapWrapper>

                  )
                } else if (data.key === 'assignedLawyers') {
                  const transformedValue = transformUser(contractInfoForm.getFieldValue(['assignedLawyers', 0]))
                  return (
                    <RowGapWrapper key={data.key} $gap={10} style={{ alignItems: 'center' }}>
                      <InputTitle>{data.label}</InputTitle>
                      <Form.Item
                        name={['assignedLawyers', 'id']}
                        style={{ width: '100%' }}
                      >
                        <FindUser pageType='contract' currentUser={data.value} form={contractInfoForm} initialValue={transformedValue.label} dataType='assignedLawyers' />
                      </Form.Item>
                    </RowGapWrapper>
                  )
                } else if (data.key === 'assignType') {
                  return (
                    <RowGapWrapper key={data.key} $gap={10} style={{ alignItems: 'center' }}>
                      <InputTitle>{data.label}</InputTitle>
                      <Form.Item
                        name={['assignType', 'id']}
                        style={{ width: '100%' }}
                      >
                        <Select
                          placeholder='사건종류'
                          options={[
                            {
                              value: 'YK-ASSIGN-TYPE-1',
                              label: '신규',
                              key: '0'
                            },
                            {
                              value: 'YK-ASSIGN-TYPE-2',
                              label: '상급심',
                              key: '1'
                            },
                            {
                              value: 'YK-ASSIGN-TYPE-3',
                              label: '별건',
                              key: '2'
                            }
                          ]}
                        />
                      </Form.Item>
                    </RowGapWrapper>
                  )
                } else {
                  return (
                    <RowGapWrapper key={data.key} style={{ alignItems: 'center' }}>
                      <InputTitle>{data.label}</InputTitle>
                      <Form.Item
                        name={[`${data.key}`]}
                        style={{ width: '100%' }}
                      >
                        <Input />
                      </Form.Item>
                    </RowGapWrapper>
                  )
                }
              })
            }
            {isDuplicateCaseNumber &&
              <>
                <h4>중복된 사건 정보</h4>
                <Table
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection
                  }}
                  pagination={false}
                  columns={duplicationTableColumns}
                  dataSource={tmpDuplicationData}
                />
              </>}
            {isDuplicateOpponent &&
              <>
                <h4>중복된 상대방 정보</h4>
                <Table
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection
                  }}
                  pagination={false}
                  columns={duplicationTableColumns}
                  dataSource={tmpDuplicationData}
                />
              </>}
          </ColumnGapWrapper>
        </Form>
      </Modal>
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <>
            <HeaderSpacer>
              <HeaderContainer>
                <PaymentHeaderTitle title={contractRecordData?.name} readOnly={readOnly} onOpenModal={onOpenModal} contractId={id} />
                <Spacer />
                {pageStatus
                  ? (
                    <>
                      <Button variant='outlined' danger onClick={() => onOpenModal(id, 'deleteContract')}>
                        <DeleteOutlined />
                        삭제하기
                      </Button>
                      <Button onClick={() => handleClose()}>
                        {pageStatusClosed
                          ? '종결하기'
                          : '종결취소'}
                      </Button>
                    </>)
                  : null}
              </HeaderContainer>
            </HeaderSpacer>
          </>
        )}
        content={
          <Form form={form}>
            <RowGapWrapper $gap={10}>
              <ColumnGapWrapper $gap={10} style={{ minWidth: '300px' }}>
                {/* <Button onClick={() => { console.log('contract', form.getFieldValue()) }}>DEBUG!</Button> */}
                <ContractTotalRecordCard contractTotal={contractTotal} receivedAmount={receivedAmount} />
                <InfoCard
                  title='사건 정보'
                  data={contractRecordData}
                  readOnly={readOnly}
                  pageStatus={pageStatus}
                  pageStatusClosed={pageStatusClosed}
                  handleModal={handleModal}
                  projectTypes={projectTypes}
                />
                <NarrowCustomCard style={{ position: 'relative' }}>
                  {(readOnly && pageStatus && pageStatusClosed &&
                    currentTab === 'contractorInfo') && (
                      <StyledEditButton
                        onClick={() => handleModal('contractor')}
                      >
                        <EditOutlined />
                      </StyledEditButton>)}
                  <CustomTabs defaultActiveKey='0' items={tabItems} onChange={onChange} />
                </NarrowCustomCard>
              </ColumnGapWrapper>
              <ColumnGapWrapper $gap={10} style={{ maxWidth: '1100px' }}>
                <ContractForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  contractRecordData={contractRecordData}
                  contractDataSource={contractDataSource}
                  setContractDataSource={setContractDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                  readOnly={readOnly}
                />
                <ExtraContractForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  contractRecordData={contractRecordData}
                  contractDataSource={extraContractDataSource}
                  setContractDataSource={setExtraContractDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                  readOnly={readOnly}
                  onSave={onSave}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  onDeleteContractPayment={onDeleteContractPayment}
                />
                <CommentForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  currentUser={currentUser}
                  contractRecordData={contractRecordData}
                  commentContractData={commentContractData}
                  setCommentContractData={setCommentContractData}
                  refetch={refetch}
                  form={form}
                  readOnly={readOnly}
                  onSave={onSave}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  formEditable={formEditable}
                />
                <DepositForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  currentUser={currentUser}
                  contractRecordData={contractRecordData}
                  depositDataSource={depositDataSource}
                  setDepositDataSource={setDepositDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                />
              </ColumnGapWrapper>
            </RowGapWrapper>
          </Form>
        }
      />
    </>
  )
}

import _ from 'lodash'
// import { graphTag } from '../../../common/config'
import { useState } from 'react'
import { callRecordType } from '../../../common/config'
import { parseDateObj } from '../../../common/util'
import COLORS from '../../../common/Theme/colors'
import fontColorContrast from 'font-color-contrast'

export const useGraphMeta = (callRecordsData, meta) => {
  const [barType, setBarType] = useState('callCount')
  const callType = _.groupBy(
    callRecordsData,
    'type'
  )
  const colorScheme = ['#102269', '#3c60a1', '#5866a1', '#5f7fff', '#8ba2ff', '#b4c3ff', '#dfe6ff', '#c7e1ff', '#89c0ff', '#5faaff', '#c7e1ff', '#cccccc', '#b0b0b0']
  const groupedByDateAndType = _.mapValues(
    _.groupBy(callRecordsData, (record) => record.inputDate),
    (records) => _.groupBy(records, 'type')
  )
  // bar graph
  const getUniqBarChartLabel = (v) => {
    const label = _.get(callRecordType, _.get(v, ['secondaryKey', 'category'])) || _.get(v, ['secondaryKey', 'name'])
    // if (_.get(meta.group.item === 'creator' ? usersByName : casesByCaseNumber, [label], []).length > 1) {
      // return label + `(${_.get(v, ['secondaryKey', meta.group.item === 'creator' ? 'email' : 'id'])})`
    // }
    return label
  }
  const dates = _.chain(groupedByDateAndType)
    .keys()
    .union(
      _.flatMap(groupedByDateAndType, (types) =>
        _.flatMap(types, (records) =>
          _.map(records, (record) => record.inputDate)
        )
      )
    )
    .uniq() // Ensure uniqueness
    .sort() // Sort the dates
    .value()
  const timeGroups = _.map(groupedByDateAndType, (value, key) => ({
    primaryKey: key,
    오프라인: value.OFFLINE ? value.OFFLINE.length : 0,
    방문: value.VISIT ? value.VISIT.length : 0,
    온라인: value.ONLINE ? value.ONLINE.length : 0
  }))
  // const chartKeys = meta.group.item === 'all'
  //   ? [
  //       'VISIT',
  //       'ONLINE',
  //       'OFFLINE'
  //     ]
  //   : [
  //       'VISIT',
  //       'ONLINE',
  //       'OFFLINE'
  //     ]
  const _barData = timeGroups


  const fetchData = _.map(_barData, ({ primaryKey: key, ...v }) => {
    let dateKey = null
    if (key) {
      if (meta.group.time === 'daily') {
        dateKey = key
      } else if (meta.group.time === 'weekly') {
        dateKey = `${parseDateObj(key)} ~ ${parseDateObj(new Date(key).setDate(new Date(key).getDate() + 6))}`
      } else if (meta.group.time === 'monthly') {
        dateKey = parseDateObj(key, 'YYYY-MM')
      } else { // yearly
        dateKey = key
      }
    }
    return {
      primaryKey: dateKey,
      ...v
    }
  })
  const groupedData = _.groupBy(fetchData, 'primaryKey')
  const sumGroupedData = _.map(groupedData, (items, key) => {
    return {
      primaryKey: key,
      오프라인: _.sumBy(items, '오프라인'),
      방문: _.sumBy(items, '방문'),
      온라인: _.sumBy(items, '온라인')
    }
  })
  const label = {
    callCount: '콜 수'
  }
  const barData = _.orderBy(sumGroupedData, ['primaryKey'], ['asc']);
  let tickValues
  let gridYValues
  let formatter
  if (barType === 'callCount') {
    const maxDailyCalls = _.max(
      _.flatten(
        barData.map(item => _.values(_.pick(item, ['OFFLINE', 'VISIT', 'ONLINE'])))
      )
    )
    const skip = Math.ceil(maxDailyCalls / 10)
    tickValues = _.range(0, maxDailyCalls + 1, skip)
    gridYValues = tickValues
    formatter = value => `${Math.floor(value)}건`
  }

  const barDataLength = _.map(barData, item => _.toPairs(item).length)
  const longestLength = _.max(barDataLength)
  const barGraphProps = {
    // margin: { top: 50, right: 130, bottom: 50, left: 60 },
    // padding: 0.15,
    margin: {
      top: 40,
      right: 60,
      bottom: 100,
      left: 60
    },
    padding: barData.length > 1 ? 0.2 : 0.6,
    minWidth: (60 * barData.length) + 60 + (meta.group.item === 'all' ? 10 : 240) + 50,
    height: meta.group.item === 'all' ? 350 : barData.length * longestLength * 2, // 바 높이 조정
    indexBy: 'primaryKey',
    // ...meta.group.item === 'all'
    //   ? { colors: d => colorScheme[d.index % colorScheme.length] }
    //   : { colors: { scheme: 'blues' } },
    data: barData,
    colorBy: 'id',
    keys: [
      '온라인',
      '오프라인',
      '방문',
      '선임'
    ],
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    innerPadding: 2,
    groupMode: 'grouped',
    // valueFormat: value => barType === 'totalMinutes' ? formatMinutesToHours(Number(value)) : `${Number(value)}개`,
    tooltip: ({ id, value, color }) => (
      <div
        style={{
          padding: 12,
          color: meta.group.item === 'all' ? `${COLORS.white}` : fontColorContrast(color),
          background: meta.group.item === 'all' ? '#A7A9AC' : color
        }}
      >
        {`${id}: ${formatter(value)}`}
      </div>
    ),
    legends:
      [{
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 100,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            },
          }
        ]
      }],
    // label: d => `${callRecordType[d.id]}`,

    // legends:
    // [
    //   {
    //     dataFrom: 'keys',
    //     anchor: 'top-right',
    //     direction: 'column',
    //     justify: false,
    //     translateX: 270,
    //     translateY: 0,
    //     itemWidth: 250,
    //     itemHeight: 20,
    //     itemsSpacing: 2,
    //     symbolSize: 20,
    //     itemDirection: 'left-to-right'
    //   }
    // ],
    axisLeft: {
      format: value => formatter(value),
      gridYValues,
      tickValues,
      tickSize: 5,
      tickPadding: 1,
      tickRotation: 0,
      legendPosition: 'middle',
      truncateTickAt: 0
    },
    axisBottom: {
      tickRotation: 0, // 날짜 꺽기 정도
      tickPadding: 10
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: v => fontColorContrast(v.color),
    // colors: { scheme: 'category10' },
    defs: [
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }],
    fill: [
      {
        match: {
          id: 'fries'
        },
        id: 'dots'
      },
      {
        match: {
          id: 'sandwich'
        },
        id: 'lines'
      }],
    borderColor: {
      from: 'color',
      modifiers: [
        [
          'opacity',
          '1.2'
        ]
      ]
    },
    axisTop: null,
    axisRight: null,
    // axisBottom: {
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'country',
    //   legendPosition: 'middle',
    //   legendOffset: 32,
    //   truncateTickAt: 0
    // },
    // axisLeft: {
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'food',
    //   legendPosition: 'middle',
    //   legendOffset: -40,
    //   truncateTickAt: 0
    // },
    // labelSkipWidth: 16,
    // labelSkipHeight: 12,
    // labelTextColor: {
    //   from: 'color',
    //   modifiers: [
    //     [
    //       'darker',
    //       1.6]]
    // },
    labelPosition: 'end',
    labelOffset: 9,
    role: 'application',
    ariaLabel: 'Nivo bar chart demo',
    barAriaLabel: e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
  }
  const onChangeBarType = (v) => setBarType(v)
  return {
    barType,
    onChangeBarType,
    barGraphProps,
    graphType: 'bar',
    graphProps: barGraphProps,
    graphTitle: '콜기록',
    dataType: 'ratioOfCallCount'
  }
}

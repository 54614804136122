import { CustomReadOnlyInput } from '../../timeCharge/components/common/CustomReadOnlyInput'
import { Button, Input } from 'antd'
import COLORS from '../Theme/colors'
import _ from 'lodash'

export const CustomSearch = ({ readOnly, onSearch, disabled, placeholder, inputWide, filterSearch, label, ...props }) => {
  if (readOnly) {
    return <CustomReadOnlyInput value={props.value} />
  }

  const debouncedSearch = _.debounce(value => {
    handleClickSearch(value)
  }, 300)

  const handleChange = e => {
    debouncedSearch(e.target.value)
  }
  const handleClickSearch = (value) => {
    onSearch && onSearch(value)
  }
  if (label === '작성자') {
    return (
      <Button
        {...props}
        style={{ color: `${COLORS.gray_3}`, display: 'flex', width: inputWide ? '700px' : '100%', maxWidth: '700px', zIndex: 3 }}
        placeholder={placeholder}
        onSearch={handleClickSearch}
        disabled={disabled}
        allowClear
      >
        {placeholder}
      </Button>
    )
  } else {
    return (
      <Input.Search
        {...props}
        style={{ paddingLeft: filterSearch ? '10px' : null, width: inputWide ? '700px' : '100%', maxWidth: '700px', zIndex: 3, minWidth: '266px' }}
        placeholder={placeholder}
        onChange={(v) => handleChange(v)}
        disabled={disabled}
        allowClear
      />
    )
  }
}

import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { getCallReservationStatusList } from '../../../call/apollo/settingQueries'

export const useCallRerservationStatusList = () => {
  const { data: _callreservationStatus, loading, refetch } = useQuery(getCallReservationStatusList, {
    fetchPolicy: 'network-only'
  })
  const callReservationStatus = _.map(_.get(_callreservationStatus, ['GetCallReservationStatusList'], []))
  return {
    loading,
    callReservationStatus,
    refetch
  }
}
